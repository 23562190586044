<template>
  <div id="recruit-section">
    <v-sheet light class="">
      <v-container class="py-4">
        <v-card outlined class="px-0" style="background-attachment: fixed; background-color: rgba(0,0,0,.3); background-blend-mode: multiply; background-image: url('/static/smooth-green-background.jpg'); background-size: cover; background-position: left center; background-repeat: no-repeat">
          <v-row class="mx-0 py-6" style="">
            <v-col cols="12" lg="6" class="text-center d-flex align-center pt-6">
              <v-spacer></v-spacer>
              <div class="d-block" style="widows: 100%;">
                <p class="text-h6 font-weight-bold white--text">LIÊN HỆ NGAY ĐỂ ĐƯỢC TƯ VẤN VỀ VIỆC LÀM</p>
                <a target="_blank" class="mx-2" href="https://www.facebook.com/nhathuoctrungson/">
                  <img src="https://cdn-icons-png.flaticon.com/128/3670/3670032.png" width="64px">
                </a>
                <a target="_blank" class="mx-2" href="https://trungsoncare.com/">
                  <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" width="64px">
                  <!-- <v-icon x-large color="orange" dark>mdi-phone-classic</v-icon> -->
                </a>

                <a target="_blank" class="mx-2" href="https://www.youtube.com/channel/UCEGFRl4OC-hf9ZWMXBsk4Kg"><img src="https://cdn-icons-png.flaticon.com/512/3670/3670163.png"  class="d-inline-block" width="64px"></a>
              </div>
              <v-spacer></v-spacer>
              
            </v-col>
            <v-col cols="12" lg="6" class="py-6">
              <v-card class="rounded-xl elevation-5" style="">
                <v-card-title class="text-center" style="word-break:break-word;">
                  <v-spacer></v-spacer>
                  NỘP HỒ SƠ CỦA BẠN
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-stepper v-model="step" class="elevation-0" alt-labels id="stepper">
                  <v-stepper-header class="elevation-0 text-center" style="flex-wrap: nowrap;">
                    <v-stepper-step step="1" color="orange accent-4" :complete="stepValid[0]" complete-icon="mdi-check">
                      Vị trí ứng tuyển
                    </v-stepper-step>

                    <v-divider></v-divider>
                    <v-stepper-step step="2" color="orange accent-4" :complete="stepValid[1]" complete-icon="mdi-check">
                      Thông tin cơ bản
                    </v-stepper-step>

                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="orange accent-4" :complete="stepValid[2]" complete-icon="mdi-check">
                      Thông tin chi tiết
                    </v-stepper-step>
                    
                    <v-divider></v-divider>
                    <v-stepper-step step="4" color="orange accent-4" :complete="stepValid[3]" complete-icon="mdi-check">
                      Học vấn
                    </v-stepper-step>
                    
                    <v-divider></v-divider>
                    <v-stepper-step step="5" color="orange accent-4" :complete="stepValid[4]" complete-icon="mdi-check">
                      Đính kèm
                    </v-stepper-step>
                    
                  </v-stepper-header>
                  <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-card flat>
                          <v-form ref="recruit_step_1" id="recruit_step_1" @submit.prevent="validStep1">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Vị trí ứng tuyển <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.jobCode" hide-details="auto" placeholder="Chọn vị trí công việc bạn ứng tuyển" @change="fetchAvailableRecruitmentRequest"
                                :items="jobs" item-value="code" item-text="name" :rules="[v => !!v || 'Chọn công việc ứng tuyển']"></v-autocomplete>

                                <div class="mt-3" v-if="jobCities.length !=0">
                                  <span class="font-weight-bold">Nơi ứng tuyển khả dụng: </span>
                                  <v-chip v-for="item in jobCities" :key="item.code" color="green" outlined pill>
                                    {{ item.name }}
                                  </v-chip>
                                </div>
                                
                                <!-- <v-chip-group
                                  active-class="primary--text"
                                  column
                                >
                                  <v-chip
                                    v-for="job in availJobs"
                                    :key="job"
                                  >
                                    <v-avatar left>
                                      <v-icon @click="getJobInfo">mdi-information-outline</v-icon>
                                    </v-avatar>
                                    {{ job.name }}
                                  </v-chip>
                                </v-chip-group> -->
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Kinh nghiệm làm việc <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select outlined  hide-details="auto"  v-model="form.yearExperience" placeholder="Kinh nghiệm làm việc" clearable
                                :items="yearExperiences"></v-select>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi làm việc mong muốn 1 <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.city1Code" clearable hide-details="auto" placeholder="Thành phố" @change="fetchJobDistrict1"
                                :items="jobCities1" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>

                                <v-autocomplete outlined v-model="form.jobDistrict1Code" clearable hide-details="auto" placeholder="Quận (huyện)"
                                :items="jobDistrict1s" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']" class="mt-2"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi làm việc mong muốn 2 <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.city2Code" clearable hide-details="auto" placeholder="Thành phố" @change="fetchJobDistrict2"
                                :items="cities" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>

                                <v-autocomplete outlined v-model="form.jobDistrict2Code" clearable hide-details="auto" placeholder="Quận (huyện)"
                                :items="jobDistrict2s" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']" class="mt-2"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Bạn biết đến trung sơn <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.recruitChannel" clearable hide-details="auto"
                                :items="recruitChannels" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn type="submit" form="recruit_step_1" color="green darken-4" style="width: 180px;" dark >
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="2">
                        <v-card flat>
                          <v-form ref="recruit_step_2" id="recruit_step_2" @submit.prevent="validStep2">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Họ & tên  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field v-model="form.name" outlined hide-details="auto" :rules="[
                                                                        v => !!v || 'Nhập vào họ tên',
                                                                        v => (v || '').length <=30 || 'Tên quá dài']"></v-text-field>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Giới tính  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-select v-model="form.gender" outlined hide-details="auto" :items='[{text: "Nam", value: true}, {text:"Nữ", value:false}]' :rules="[v=> v!=null ||'Chọn 1 dữ liệu' ]"></v-select>
                              </v-col>
                              
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ngày sinh  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-card class="d-inline-flex" outlined>
                                  <v-date-picker no-title class="mx-auto" locale="vi" full-width color="green darken-2"
                                        v-model="form.birthday"
                                    ></v-date-picker>
                                </v-card>
                                <v-text-field hide-details="auto" maxlength="10" v-model="formatedBirthDay" outlined :rules="[
                                                                                                        rules.required,
                                                                                                        rules.validDate,
                                                                                                        rules.minDate
                                                                                                        ]" 
                                              hint="Định dạng ngày dd/mm/yyyy"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Số điện thoại  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field v-model="form.phone" hide-details="auto" placeholder="ví dụ 0972xxxxxx" outlined :rules="[
                                                                          v => !!v || 'Nhập SDT', v => (v || '').length <=15 || 'SDT quá dài',
                                                                          v => (v || '').length >=10 || 'SDT quá ngắn',
                                                                          v =>  !/\D/.test(v) || 'SDT không đúng']"></v-text-field>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Email <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined type="email" placeholder="ví dụ: email@gmail.com" v-model="form.mail" hide-details="auto" :rules="[
                                                                                          v => !!v || 'Nhập Email', v => (v || '').length <=50 || 'Email quá dài',
                                                                                          rules.validEmail]"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn type="submit" form="recruit_step_2" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="3" >
                        <v-card flat>
                          <v-form ref="recruit_step_3" id="recruit_step_3" @submit.prevent="validStep3">
                            <v-row style="">                                                                                          
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Chiều cao <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                  <v-text-field outlined hide-details="auto" v-model="form.height" persistent-placeholder hint="ví dụ 1.8" persistent-hint
                                                placeholder="ví dụ 1.8" suffix="(m)" :rules="[ 
                                                                                                v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                v=> !!v || 'Nhập dữ liệu này'
                                                                                              ]"></v-text-field>
                              </v-col>
                                                                                                                        
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Cân nặng <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined hide-details="auto" v-model="form.weight" label="Cân nặng"  hint="ví dụ 50" persistent-hint
                                                suffix="(kg)" persistent-placeholder placeholder=" ví dụ: 80" :rules="[ 
                                                                                                  v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                  v=> !!v || 'Nhập dữ liệu này'
                                                                                                ]"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Quốc tịch <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.originCode" placeholder="Việt Nam, ..." persistent-placeholder hide-details="auto"
                                :items="origins" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Dân tộc <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.ethnicityCode" placeholder="Dân tộc Kinh, Hoa, ..." persistent-placeholder hide-details="auto"
                                :items="ethnicities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Tôn giáo <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.religionCode" placeholder="Phật giáo, Công giáo,..." persistent-placeholder hide-details="auto"
                                :items="religions" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                CCCD  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined v-model="form.idNumber" hide-details="auto" :rules="[
                                                                                          v => !!v || 'Nhập chứng minh', v => (v || '').length <=12 || 'CMND quá dài',
                                                                                          v => (v || '').length >=9 || 'CMND quá ngắn',
                                                                                          v =>  !/\D/.test(v) || 'CMND không đúng']"></v-text-field>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi cấp  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.idPlace" :items="idPlaces" hide-details="auto"
                                :rules="[v=>!!v || 'Nhập dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ngày cấp  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-card class="d-inline-flex" outlined>
                                  <v-date-picker no-title class="mx-auto" locale="vi" full-width color="green darken-2" 
                                        v-model="form.idDate"
                                    ></v-date-picker>
                                </v-card>
                                <v-text-field maxlength="10" v-model="formatedIdDate" outlined :rules="[
                                                                                                        rules.required,
                                                                                                        rules.validDate
                                                                                                        ]" 
                                              hint="Định dạng ngày dd/mm/yyyy" hide-details="auto"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Địa chỉ thường trú <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col class="">
                                <v-autocomplete outlined v-model="form.cityCode" placeholder="Thành phố" persistent-placeholder
                                  @change="fetchDistrict(form.cityCode)"
                                  :items="cities" item-value="code" item-text="name" :rules="[
                                                                                        v => !!v || 'Chọn thành phố của bạn']">
                                </v-autocomplete>
                                
                                <v-autocomplete outlined v-model="form.districtCode" placeholder="Quận" persistent-placeholder @change="fetchWard(form.districtCode)"
                                :items="districts" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
                                
                                <v-autocomplete outlined v-model="form.wardCode" placeholder="Phường" persistent-placeholder :rules="[ v => !!v || 'Chọn phường của bạn']"
                                  :items="wards" item-value="code" item-text="name"></v-autocomplete>
                                  
                                <v-textarea outlined rows=3 v-model="form.address" placeholder="ví dụ đường CMT8, 2xx/5 hẻm 5, phường 5, quận Bình Thủy, Thành phố Cần Thơ" persistent-placeholder :rules="[v=>!!v || 'Nhập dữ liệu']" hide-details="auto"></v-textarea>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Địa chỉ tạm trú <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                  <v-autocomplete outlined v-model="form.dCityCode" placeholder="Thành phố" persistent-placeholder
                                    @change="fetchDistrict2(form.dCityCode)"
                                    :items="cities" item-value="code" item-text="name" :rules="[
                                                                                          v => !!v || 'Chọn thành phố của bạn']">
                                  </v-autocomplete>
                                
                                  <v-autocomplete outlined v-model="form.dDistrictCode" placeholder="Quận" persistent-placeholder @change="fetchWard2(form.dDistrictCode)"
                                  :items="district2s" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
                                
                                  <v-autocomplete outlined v-model="form.dWardCode" placeholder="Phường" persistent-placeholder :rules="[ v => !!v || 'Chọn phường của bạn']"
                                    :items="ward2s" item-value="code" item-text="name"></v-autocomplete>

                                  <v-textarea outlined v-model="form.dAddress" placeholder="Địa chỉ" :rules="[v=>!!v || 'Nhập dữ liệu']"></v-textarea>
                              </v-col>
                              
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn form="recruit_step_3" type="submit" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <!-- data remake -->
                      <v-stepper-content step="4">
                        <v-card flat>
                          <v-form ref="recruit_step_4" id="recruit_step_4" @submit.prevent="validStep4">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Học vấn  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.educationLevelCode" placeholder="Học vấn" persistent-placeholder
                                :items="educationLevels" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Chuyên ngành <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.educationMajorCode" placeholder="Chuyên ngành"
                                :items="educationMajors" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Cơ sở đào tạo <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.universityCode" placeholder="Cơ sở đào tạo"
                                :items="universities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Xếp loại tốt nghiệp <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>       
                                <v-autocomplete outlined hide-details="auto" v-model="form.graduateTypeCode" placeholder="Xếp loại tốt nghiệp" persistent-placeholder
                                :items="[
                                          {text: 'Giỏi', value: 'GIOI'},
                                          {text: 'Khá', value: 'KHA'},
                                          {text: 'Trung Bình', value: 'TRUNGBINH'},
                                          {text: 'Khác', value: 'KHAC'},
                                          {text: 'Chưa tốt nghiệp', value: 'NONE'},
                                        ]" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Loại chứng chỉ hành nghề Dược <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>       
                                <v-select outlined v-model="form.pharmaCert" :items="pharmaCerts"  persistent-placeholder
                                          placeholder="Loại chứng chỉ hành nghề Dược" :rules="[ v => !!v || 'Chọn dữ liệu']" clearable hide-details="auto">
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn type="submit" form="recruit_step_4" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="5">
                        <v-card flat>
                          <v-form ref="recruit_step_5" id="recruit_step_5" @submit.prevent="validStep5">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ảnh chân dung của bạn <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <!-- <v-hover v-slot="{ hover }">
                                    <v-avatar tile class="rounded-xl"
                                        color="grey" size="128" >
                                        <v-img :src="avatarPreview">
                                            <v-expand-transition>
                                                <div v-if="!avatarFile" class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal white--text"
                                                style="height: 100%;" @click="chooseAvatar">
                                                    <v-icon large style="cursor:pointer">mdi-camera</v-icon>Avatar
                                                </div>
                                            </v-expand-transition>
                                        </v-img>
                                    </v-avatar>
                                </v-hover> -->
                                
                                <v-img :src="avatarPreview" class="rounded-xl mb-2 mx-auto" width="150px" height="150px">
                                </v-img>
                                <v-file-input show-size outlined
                                label="Avatar" ref="avatarInput" v-model="avatarFile" single-line  prepend-icon="" hide-details="auto"
                                @change="uploadAvatar" :rules="[v=>!!v||'Hãy chọn ảnh chân dung của bạn']">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                CCCD mặt trước <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="idImageFrontFile" clearable outlined :rules="[v=>!!v||'Hãy chọn ảnh CCCD mặt trước của bạn']" placeholder="Ảnh chụp CCCD mặt trước" persistent-placeholder hide-details="auto" 
                                single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                CCCD mặt sau <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="idImageBackFile" clearable outlined :rules="[v=>!!v||'Hãy chọn ảnh CCCD mặt sau của bạn']" placeholder="Ảnh chụp CCCD mặt sau" persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                File CV <small class="ml-2 orange--text text--darken-2"> (ứng viên viên nên gởi CV)</small> <span class="red--text ml-2" ></span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="cvFile" accept=".pdf" clearable outlined placeholder="File  CV định dạng PDF" persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                File đính kèm khác <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input clearable outlined v-model="attachmentFile" multiple accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpg" placeholder="Giấy khám sức khỏe, CV, v.v.v." persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>

                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn style="width: 90px;" dark color="red darken-4" @click="step--" outlined>Quay lại</v-btn>
                              <v-btn type="submit" form="recruit_step_5" style="width: 90px;" dark color="green darken-4" :loading="isLoading">GỞI HỒ SƠ</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-col>

          </v-row>
        </v-card>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import http from "./services/http-common";
import ApiService from "./services/ApiServices";
import moment from 'moment';
import router from "../router/index";
const dateRegex = new RegExp('[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}');
import Swal from 'sweetalert2';

var minBirthDay = moment().startOf("year").subtract(19, "years").format("YYYY-MM-DD");
export default {
  computed: {
    jobCities1() {
      return this.jobCities.length ==0 ? this.cities : this.jobCities
    }, 
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    },
    avatarError: function(){
      if(this.avatarFile===null)
        return true;
      console.log(this.avatarFile)
      return false;
    },
    height () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    
    formatedBirthDay: {
      get() {
        return moment(this.form.birthday).format("DD/MM/YYYY")
      },
      set(value) {
        const possibleFormats = ["D/M/YYYY", "DD/MM/YYYY"]
          let parsedDate = moment(value,possibleFormats, true);
          if (parsedDate.isValid()) {
            this.form.birthday = parsedDate.format("YYYY-MM-DD")
          }
      }
    },

    formatedIdDate: {
      get() {
        return moment(this.form.idDate).format("DD/MM/YYYY")
      },
      set(value) {
        const possibleFormats = ["D/M/YYYY", "DD/MM/YYYY"]
          let parsedDate = moment(value,possibleFormats, true);
          if (parsedDate.isValid()) {
            this.form.idDate = parsedDate.format("YYYY-MM-DD")
          }
      }
    }
  },
  methods: {
    //validation
    validStep1() {
      this.stepValid[0] = this.$refs.recruit_step_1.validate()
      if(this.stepValid[0]){
        this.nextStep()
      }
    },
    validStep2() {
      this.stepValid[1] = this.$refs.recruit_step_2.validate()
      if(this.stepValid[1]){
        this.nextStep()
      }
    },
    validStep3() {
      this.stepValid[2] = this.$refs.recruit_step_3.validate()
      if(this.stepValid[2]){
        this.nextStep()
      }
    },
    validStep4() {
      this.stepValid[3] = this.$refs.recruit_step_4.validate()
      if(this.stepValid[3]){
        this.nextStep()
      }
    },
    validStep5() {
      this.stepValid[4] = this.$refs.recruit_step_5.validate()
      if(this.stepValid[4]){
        this.applyJob()
      }
      
    },
    
    // utils
    createBase64Image(FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64_cccd_front = event.target.result;
      }
      reader.readAsDataURL(FileObject);
    },
    
    nextStep() {
      this.step ++
      this.$vuetify.goTo('#stepper', this.scrollOptions)
    },
    
    uploadAvatar(file) {  
        if (file) {
          this.avatarPreview = URL.createObjectURL(file);
        }
    },
    chooseAvatar(){
      this.$refs.avatarInput.$refs.input.click()
    },

    //fetch sections
    fetchJob () {
      return http.get("candidates/fetch-job")
      .then(res => {
          this.jobs = res.data.items;
      }).catch(err => {throw err});
    },
    fetchCity () {
      return http.get("cities", {
          params: {dropDown: 1}
      }).then(res => {
          this.cities = res.data.items;
      }).catch(err => {
          console.log(err);
      });
    },
    fetchDistrict (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.districts = res.data;
        });
    },
    fetchJobDistrict1 (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.jobDistrict1s = res.data;
        });
    },
    fetchJobDistrict2 (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.jobDistrict2s = res.data;
        });
    },
    fetchWard (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.wards = res.data;
        });
    },

    fetchDistrict2 (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.district2s = res.data;
        });
    },
    fetchWard2 (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.ward2s = res.data;
        });
    },
    fetchEducationLevel(){
      ApiService.fetchEducationLevel().then(res=>{
        this.educationLevels = res.data;
      })
    },
    fetchEducationMajor(){
      ApiService.fetchEducationMajor().then(res=>{
        this.educationMajors = res.data;
      })
    },
    fetchUniversity(){
      ApiService.fetchUniversity().then(res=>{
        this.universities = res.data;
      })
    },
    fetchEthnicity(){
      return ApiService.fetchEthnicity().then(r => {
        this.ethnicities =  r.data
      })
    },
    fetchOrigin(){
      return ApiService.fetchOrigin().then(r => {
        this.origins =  r.data
      })
    },
    fetchReligion(){
      return ApiService.fetchReligion().then(r => {
        this.religions =  r.data
      })
    },

    fetchAvailableRecruitmentRequest( val) {
      if(val) {
        this.getJobCity(this.form.jobCode)
        return http.get("jobs/fetch-available-job/"+val).then(r => r.data).then(d => {
          this.availJobs = d.data
        })
      }
    },

    getJobInfo(){
      alert("Job info")
    },

    getJobCity(code) {
      return http.get("jobs/get-city/"+code).then(res => {
        this.jobCities = res.data.data
      })
    },

    applyJob() {
      var formData = new FormData();
      if(this.attachmentFile != null){
        this.form.attachmentFile = this.attachmentFile.forEach(file => {
          formData.append("attachmentFile", file);
        });
      }
      if(this.form.birthday ==null)
      {
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Hãy nhập ngày sinh của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      if(this.avatarFile)
        formData.append("avatar", this.avatarFile);
      else
      {
        this.avatarFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chọn ảnh avatar của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }

      if(this.idImageFrontFile)
        formData.append("idImageFront", this.idImageFrontFile);
      else
      {
        this.idImageFrontFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chụp CMND/CCCD mặt trước của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      
      if(this.idImageBackFile)
        formData.append("idImageBack", this.idImageBackFile);
      else
      {
        this.idImageFrontFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chụp CMND/CCCD mặt sau của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }

      if(this.cvFile)
        formData.append("cvFile", this.cvFile);
      // else
      // {
      //   this.cvFile = undefined;
      //   this.valid = false;
        
      //   Swal.fire(
      //     {
      //       title: 'Thông báo',
      //       html: 'Chọn file CV của bạn',
      //       icon:'error',
      //       showCloseButton: false
      //     }
      //   );
      //   return;
      // }

      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }

      // this.$refs.candidate.validate();
      this.valid =true
      if(this.valid){
        this.isLoading=true;
        return http.post(this.CANDIDATE_URL, formData).then(res=>{
          // this.$refs.candidate.reset();
          this.form = Object.assign({}, this.defaultForm);
          this.isLoading=false;
          this.form.height = 0;
          this.form.weight = 0;

          Swal.fire(
            {
              title: 'Thông báo',
              html: '<p>Cảm ơn bạn đ&atilde; ứng tuyển v&agrave;o Hệ Thống Nh&agrave; Thuốc Trung Sơn.</p> <p>Theo nhu cầu tuyển dụng nhận sự của c&ocirc;ng ty. Ch&uacute;ng t&ocirc;i sẽ chủ động li&ecirc;n hệ với c&aacute;c hồ sơ ph&ugrave; hợp để th&ocirc;ng b&aacute;o lịch phỏng vấn.</p> <p>Th&acirc;n mến!!</p><p>&nbsp;</p><p><em>Th&ocirc;ng tin li&ecirc;n hệ SDT: <a class="text-is-phone-number">088 8786787</a><a class="text-is-phone-number">&nbsp;</a><span class="text">/ </span><a class="text-is-phone-number">0867742768</a></em></p>',
              icon:'success',
              closeButtonText: 'Đồng ý',
              showCloseButton: true
            }).then(result => {
              window.location.reload();
            })
        }).catch(err=>{console.log(err)})
        .finally(()=>{
          this.isLoading=false;
        })
      } else {
          let eventObj = {
              color: "error",
              message: "Kiểm tra lại dữ liệu",
              toast: false,
          };
          EventBus.$emit('hasError', eventObj);
      }
    },
  },
  data() {
    return {
      
      stepValid: [false, false, false, false, false],
      step: 1,
      
      CANDIDATE_URL: "candidates",
      jobs:[],
      cities:[],
      districts:[],
      wards:[],
      district2s:[],
      ward2s:[],
      jobDistrict1s: [],
      jobDistrict2s: [],
      availJobs: [],
      jobCities: [],

      ethnicities:[],
      origins:[],
      religions:[],

      educationLevels:[],
      educationMajors:[],
      universities:[],
      yearExperiences:[
        {text: " Chưa có kinh nghiệm", value: 0},
        {text: " 6 tháng", value: 0.5},
        {text: " 1 năm", value: 1},
        {text: " 2 năm", value: 2},
        {text: " 3 năm", value: 3},
        {text: " Hơn 3 năm", value: 4},
      ],
      valid: true,
      avatarPreview: "/sample.png",
      base64_cccd_front: "",
      attachmentFile:undefined,
      avatarFile:undefined,
      idImageFrontFile:undefined,
      idImageBackFile:undefined,
      cvFile: undefined,
      isLoading:false,
      recruitChannels:[
        {text: "Mạng xã hội", value: "MXH"},
        {text: "Tờ rơi", value: "TR"},
        {text: "Được giới thiệu", value: "N"},
        {text: "Trang tuyển dụng", value: "TTD"},
      ],
      pharmaCerts:[
        {text: "Không có", value: "KHONG"},
        {text: "CCHN Cao Đẳng", value: "CD"},
        {text: "CCHN Đại Học", value: "DH"},
      ],
      form: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",

        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
        jobDistrict1Code:'',
        jobDistrict2Code:'',
      },
      defaultForm: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",
        
        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
        jobDistrict1Code:'',
        jobDistrict2Code:'',
      },
      rules: {
        required: v=>!!v || 'Nhập dữ liệu',
        validEmail: v =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email không đúng',
        validDate: v=> dateRegex.test(v) || 'Dữ liệu không đúng, định dạng ngày dd/mm/yyyy',
        minDate: v=> !moment(v, "dd/mm/yyyy").isAfter(minBirthDay) || 'Bạn cần đủ tuổi để nộp hồ sơ'
      },
    }
  },

  mounted() {
    this.fetchJob();
    this.fetchCity();
    this.fetchEducationLevel();
    this.fetchEducationMajor();
    this.fetchUniversity();
    this.fetchEthnicity();
    this.fetchOrigin();
    this.fetchReligion();
  }
}
</script>